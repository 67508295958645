$(document).ready(function () {

    //Navigation Toolbar Service
    $.getScript("/ResourcePackages/Bootstrap4MI/assets/dist/js/Services/NavigationToolbarUIService.js", function (data, textStatus, jqxhr) {
        var navigationToolbarUI = new NavigationToolbarUI();
        navigationToolbarUI.toggleToolbar("#mi-corpo-floating-toolbar");
    });

    //Contact Form Response
    $.getScript("/ResourcePackages/Bootstrap4MI/assets/dist/js/Services/ContactFormResponseService.js", function (data, textStatus, jqxhr) {
        var contactFormResponse = new ContactFormResponse();
        contactFormResponse.showMessage();
    });

});
